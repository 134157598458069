* {
    box-sizing: border-box !important;
    margin: 0;
    padding: 0;
}

body {
    padding: 0% !important;
    margin: 0;
    background-image: linear-gradient(45deg, #000000, #434343);
}

/* NAV-BAR */
.navbar {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 999999;
    background-color: rgba(0, 0, 0, 0.397);
    backdrop-filter: blur(15px);
    display: flex;
    transition: transform 0.3s;
}

.navbar.hidden {
    transform: translateY(-100%);
}

.navbar .toggle-btn {
    color: orange;
    font-size: 2.3rem;
    cursor: pointer;
    margin-right: 1rem;
}

.nav-link:hover span {
    transform: translateY(-110%);
}

.nav-link:hover {
    color: orange;
    font-weight: bold;
}

.nav-link {
    font-weight: bold;
    font-size: 16px;
    height: 27px;
    overflow: hidden;
    letter-spacing: 2px;
    color: white;
    font-family: cafe;
}

.nav-link span {
    position: relative;
    display: inline-block;
    transform: 0.3s;
}

.nav-link span::before {
    position: absolute;
    top: 100%;
    content: attr(data-hover);
    transform: translate3d(0, 0, 0);
}

.nav-item span .nav-link.active {
    color: #FF8235;
}

@keyframes beforeAnimate {
    0% {
        width: 0%;
        transform: translateX(-50%);
    }

    100% {
        width: 100%;
        transform: translateX(0%);
    }
}


nav a::before {
    content: "";
    background: linear-gradient(to right, #3c69ff, #3cffb4, #bbff3c, #ffb13c, #ff3c3c);
    width: 100%;
    height: 2.4px;
    position: absolute;
    bottom: -5px;
    left: 0px;
    display: none;
    animation: beforeAnimate .5s;
}

.btn-nav {
    background-color: orange;
    color: white;
    border: none;
    outline: none;
    overflow: hidden;
    border-radius: 20px;
    font-weight: bold;
    cursor: pointer;
    margin: -0.5rem -1rem;
}

#loginSubtn {
    background-color: transparent;
    border: none;
    padding: 1rem;

}

.btn-navLoguin {
    background-color: orange;
    color: white;
    padding: 0.5rem 1rem;
    border: none;
    outline: none;
    border-radius: 20px;
    font-weight: bold;
    cursor: pointer;
}

.btn-userDrop {
    background-color: orange;
    color: white;
    border: none;
    outline: none;
    width: 100%;
    font-weight: bold;
    cursor: pointer;
    padding: 1rem;
}

.btn-userDrop:hover {
    background-color: #FF8235;
}

.btn-userDrop a {
    text-decoration: none;
    color: white;
    font-family: cafe;
    letter-spacing: 3px;
    text-transform: uppercase;
}

.btn-nav:hover {
    background-color: #FF8235;
    scale: 1.07;
    color: #FF8235;
}

.btn-navLoguin a:hover {
    scale: 1.2;
    color: #FF8235;
}

.btn-navLoguin a.active {
    color: black;
    text-decoration: none;
    font-family: cafe;
    font-weight: bolder;
    letter-spacing: 2px;
    text-transform: uppercase;
}

/* DROPDOWN MENU*/
.navbar-default .navbar-nav>.open>a.dropdown-toggle {
    background-color: #f4511e;
}

.dropdown li a {
    color: #ffffff;
}

.dropdown ul {
    background-color: rgba(0, 0, 0, 0.5);
}

.dropdownbutton {
    background-color: rgba(218, 112, 214, 0) !important;
    border: none;
    display: none;
}

.dropdown_menu.show {
    position: fixed !important;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100vh;
    background-color: rgba(0, 0, 0, 0.733);
    backdrop-filter: blur(15px);
    inset: 0 !important;
    transform: none !important;
    transition: all 300ms ease-in-out;
}

.dropdown_menu.show .navContainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    background-color: #111;
    position: relative;
    border: solid 1px #A1FFCE;
}

.dropdown .dropdown_menu .dropdown-item {
    padding: 0.7rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 16px 0;
    position: relative;
    width: 30%;
}

.dropdown .dropdown_menu .dropdown-item::before,
.dropdown .dropdown_menu .dropdown-item::after {
    content: '';
    position: absolute;
    width: 0;
    height: 3px;
    background-color: #FF8235;
    opacity: 0;
    transition: all 300ms ease-in-out;
}

.dropdown .dropdown_menu .dropdown-item::before {
    top: 0;
    left: 0;
}

.dropdown .dropdown_menu .dropdown-item::after {
    bottom: 0;
    right: 0;
}

.dropdown .dropdown_menu .dropdown-item:hover::before,
.dropdown .dropdown_menu .dropdown-item:hover::after {
    width: 75%;
    opacity: 1;
}

.dropdown .dropdown_menu .dropdown-item a {
    color: white;
    font-size: clamp(1.5rem, 1.8vw, 2rem);
    text-decoration: none;
    text-transform: uppercase;
    position: relative;
    letter-spacing: 6px;
    font-family: cafe;
}

.dropdown .dropdown_menu .dropdown-item a:hover {
    color: #FF8235;
}

.dropdown-item:focus,
.dropdown-item:hover {
    background-color: transparent;
}

.responsive-Navlogo {
    max-width: 100%;
    height: auto;
}

/* FINAL NAV-BAR */

/* INDEX*/
/* PARALLAX */
.fondoParallax {
    position: relative;
    min-width: 100%;
    min-height: 100vh;
    display: flex;
    background-attachment: fixed;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("./images/parallaxOne.jpg");
    justify-content: center;
    align-items: center;
}

/* Smoke text */
.caption {

    text-align: center;

}

.caption ul {
    margin: 0;
    padding: 0;
    display: flex;
}

.caption ul li {
    list-style: none;
    color: white;
    font-size: clamp(3.1em, 5vw, 6em);
    font-family: cabinest;
    letter-spacing: 10px;
}

.caption h3 {
    list-style: none;
    color: orange;
    font-size: clamp(1.5rem, 2vw, 2rem);
    font-family: cabin;
    letter-spacing: 10px;
    text-shadow: 3px 1.5px 1px rgba(0, 0, 0, 1);
}

.caption ul li {
    color: #ffffff;
    text-shadow: 4px 4px 2px rgba(0, 0, 1, 1);
}

.caption ul:hover li {
    animation: smocke 4s linear infinite;
}

@keyframes smocke {
    0% {
        transform: rotate(0deg)translateY(0px);
        opacity: 1;
        filter: blur(1px);
    }

    100% {
        transform: rotate(45deg) translateY(-200px);
        opacity: 0;
        filter: blur(20px);
    }

}

.caption ul li:nth-child(1) {
    animation-delay: 0s;
}

.caption ul li:nth-child(2) {
    animation-delay: .4s;
}

.caption ul li:nth-child(3) {
    animation-delay: .8s;
}

.caption ul li:nth-child(4) {
    animation-delay: 1.2s;
}

.caption ul li:nth-child(5) {
    animation-delay: 1.6s;
}

.caption ul li:nth-child(6) {
    animation-delay: 2.0s;
}

.caption ul li:nth-child(7) {
    animation-delay: 2.4s;
}

.caption ul li:nth-child(8) {
    animation-delay: 2.8s;
}

.caption ul li:nth-child(9) {
    animation-delay: 3.2s;
}

.caption ul li:nth-child(10) {
    animation-delay: 3.6s;
}

/* text Animado cambia palabra */


.animated-info {
    position: relative !important;
    height: 3rem;
    margin-bottom: 2rem;
}

.animated-item span:nth-child(2n+2) {
    animation-delay: 4s;
    color: #ff8b2c;
}

.animated-item span {
    font-family: cabinest;
    letter-spacing: 2px;
    font-size: 2rem;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    animation: BottomTotop 8s linear infinite 0s;
    color: rgb(17, 255, 243);
}


@keyframes BottomTotop {
    0% {
        opacity: 0;
    }

    5% {
        opacity: 0;
        transform: translateY(5px);
    }

    10% {
        opacity: 1;
        transform: translateY(0px);
    }

    25% {
        opacity: 1;
        transform: translateY(0px);
    }

    30% {
        opacity: 0;
        transform: translateY(5px);
    }

    80% {
        opacity: 0;
    }

    100% {
        opacity: 0;
    }

}

/* About */

.about {
    background-color: #00000079 !important;
    width: 100%;
    backdrop-filter: blur(15px);
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
}

.aboutDiv {
    left: 50%;
    top: 50%;
    text-align: center;
}

.aboutTitulo {
    width: 100%;
    color: transparent;
    text-align: center;
    font-family: cabin;
    font-size: clamp(2.8rem, 3.5vw, 4rem);
    font-weight: bold;
    background: #FF8235;
    background-position-y: 58%;
    background-clip: text;
    background-size: cover;
    text-transform: uppercase;
}

#serviceDetailsTitulo {
    width: 100%;
    color: #FF8235;
    text-align: center;
    font-family: cabin;
    font-size: clamp(2.8rem, 3.5vw, 4rem);
    font-weight: bold;
    text-transform: uppercase;
    text-shadow: 2px 2px 2px rgba(0, 0, 1, 1);
    margin-bottom: 1rem;
}

.about .aboutDiv .aboutTitulo {
    margin-top: 1rem;
    margin-bottom: 6rem;
}

.divSubText h3 {
    color: #fff8e8;
    font-family: cafe;
    letter-spacing: 2px;
    margin-top: 4rem;
    text-align: center;

}

.divSubText span:nth-child(1) {
    border-bottom: 2px solid #FF8235;
    color: #FF8235;
    font-family: cafe;
}

.divSubText span:nth-child(2) {
    color: #30E8BF;
    border-bottom: 2px solid #30E8BF;
    font-family: cafe;
}

.divSubText h2 {
    text-shadow: -1px 1px 0px rgb(0, 0, 0),

        -15px 10px 20px rgba(0, 0, 0, 0.5);
}

.divBienvenida h2 {
    color: #16b6d6;
    font-family: Cabin;
    font-size: clamp(2rem, 2.2vw, 3rem);
    text-align: center;
}

.divBienvenida span {
    font-family: cabinest;
    color: #78ffd6;
    letter-spacing: 4px;
}

.text-about {
    position: relative;
    margin-top: 3rem !important;
    font-family: cafe;
    color: white;
    font-size: clamp(1.13rem, 1.4vw, 1.2rem);
}

.textDudas {
    font-size: clamp(.8rem, 1vw, .9rem);
    color: #b4a0fd;
}

.custom-btn {
    background: linear-gradient(105deg, #B3FFAB, #12FFF7);
    color: black;
    font-weight: bold;
    border-radius: 50px;
    padding: 13px 29px;
    font-size: clamp(1rem, 1vw, .9rem);
    line-height: normal;
    overflow: hidden;
    transition: all 0.4s ease;
    margin-top: 1rem;
    margin-bottom: 2rem;
}

.scrollAbout {
    position: relative;
    display: flex;
    overflow: hidden;
    height: 5rem;
    -webkit-mask-image: linear-gradient(90deg, transparent, #fff 20%, #fff 80%, transparent)
}

.scrollAbout div {
    white-space: nowrap;
    height: 2rem;
    animation: scrollAnimate var(--t) linear infinite;
    animation-delay: calc(var(--t) * -1);
}

.scrollAbout div:nth-child(2) {
    animation: scrollAnimate2 var(--t) linear infinite;
    animation-delay: calc(var(--t) / -2);
}

.scrollAbout div span {
    display: inline-flex;
    margin: 10px;
    font-family: cafe;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    background: linear-gradient(90DEG, #43C6AC, #ffd194);
    color: #000000;
    padding: 5px 10px;
    border-radius: 5px;
    transition: 0.5s;
    font-weight: bold;
}

.scrollAbout div span:hover {
    background: linear-gradient(45deg, #30E8BF, #FF8235);
    cursor: pointer;
}

/* About Cards */
.swiper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}

.card {
    width: 20em !important;
    height: 34rem !important;
    background: #2e2e2e;
    box-shadow: 0 0 2em rgba(0, 0, 0, .2);
    padding: 2em 1em;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.swiperContainer {
    width: 100%;
}

.swiper-slide:not(.swiper-slide-active) {
    filter: blur(1px);
}

.card_image {
    width: 7em;
    height: 7em;
    border-radius: 50%;
    border: 3px solid #000000;
    background: linear-gradient(to bottom, #ff8235e7 50%, #ffffff 50%);
    padding: 1em;
    margin-bottom: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
}

.card_image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.card_content {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.card_title {
    font-size: 1.6em;
    font-weight: bold;
    position: relative;
    top: .2em;
    letter-spacing: 1px;
    text-align: center;
    color: #ff8235;
    font-family: cafe;
    text-shadow: 1px 1px 1px rgba(1, 1, 1, 1)
}

.card_text {
    text-align: center;
    font-size: clamp(1.13rem, 1.4vw, 1.2rem);
    margin: 1.5em 0;
    font-family: cafe;
    color: white;
}

.card-btn {
    position: absolute;
    bottom: 15px;
    left: 100px;
    background: linear-gradient(105deg, #B3FFAB, #12FFF7);
    color: #000000;
    font-size: clamp(1rem, 1vw, .9rem);
    text-transform: uppercase;
    font-weight: 600;
    border: none;
    padding: .7em .9em;
    border-radius: 50px;
    cursor: pointer;
}



@keyframes scrollAnimate {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(-100%);
    }

}

@keyframes scrollAnimate2 {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-200%);
    }

}

/* SERVICIOS */

.fondoAux {
    background-color: #0a0a0a42;
    width: 100%;
    backdrop-filter: blur(5px);
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
}

.fondoAux .aboutDiv {
    margin-bottom: -2rem;
    margin-top: 7rem;
}

.iconServices a {
    text-decoration: none;
    color: #FF8235;
    font-size: clamp(1.4rem, 1.7vw, 1.8rem);
    margin-left: 1rem;
}

/* DASHBOARD */


/* NEWSERVICE-DASHBOARD */
.containerCard {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 0;
    flex-wrap: wrap;
}

.containerCard .boxCard {
    position: relative;
    width: 320px;
    height: 430px;
    color: white;
    background: #222;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 30px;
    transition: 0.5s;
}

.containerCard .boxCard:hover {
    transform: translateY(-20px);
}

.containerCard .boxCard::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(45deg, #ff8235, #001510);
}

.containerCard .boxCard::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(45deg, #ff8235, #001510);
    filter: blur(5px);
}

.containerCard .boxCard span {
    position: absolute;
    top: 6px;
    left: 6px;
    right: 6px;
    bottom: 6px;
    background: #1c1c1c;
    z-index: 2;
}

.containerCard .boxCard span::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    pointer-events: none;
}

.containerCard .boxCard .contentCard {
    position: relative;
    z-index: 10;
    padding: 20px 40px;
    height: 80% !important;
}



.containerCard .boxCard .contentCard h2 {
    font-family: cabin;
    color: #ff8235;
    font-size: 1.7rem;
    text-align: center;
    margin-bottom: 20px;
}

.containerCard .boxCard .contentCard h5 {
    font-family: summer;
    color: #f0bf6a;
    letter-spacing: 1.5px;
}

.containerCard .boxCard .contentCard h4 {
    font-family: Cabin;
    letter-spacing: 2px;
    color: #8effd9;
    text-align: center;
    background-color: #111;
    border-radius: 50px;
    border: solid 1px #30E8BF;
    width: 70px;
    height: 70px;
    padding-top: 20px;
    position: absolute;
    top: -4rem;
}

.containerCard .boxCard .contentCard p {
    font-family: cafe;
    color: #ffffff;
    text-align: center;
    margin-bottom: 10px;
    line-height: 1.4em;
}

.containerCard .boxCard .btn {
    position: absolute;
    bottom: 0px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    border-radius: 40px;
    text-align: center;
    background: white;
    left: 34%;
}

.containerCard .boxCard .btn:hover {
    background-color: #ff8;
}

.containerCard .boxCard .btn a {
    font-size: 1rem;
    color: #111;
    font-weight: 700;
    font-family: cafe;
    cursor: pointer;
    text-decoration: none;
    letter-spacing: 1px;
    text-transform: uppercase;
}

/* REQUIRES */
.containeRequires {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
    margin-top: -5rem;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.containeRequires .cardFlip {
    position: relative;
    height: 250px;
    background-color: transparent;
    transition: 0.5s;
    display: flex;
    width: 330px;
    margin: 40px;
    border-radius: 20px;
    overflow: hidden;
    border: none;
}

.containeRequires .cardFlip .front {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    backface-visibility: hidden;
    transform: rotateX(0deg);
    transition: 0.5s;
    background: transparent;
    background-position-y: bottom;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #FFF8E8;
}

.containeRequires .cardFlip:hover .front {
    transform: rotateX(-180deg);
}

.containeRequires .cardFlip .front span {
    position: absolute;
    border: 2px solid #FFF8E8;
    padding: 10px;
    width: 95%;
    height: 95%;
}

.containeRequires .cardFlip .front .context {
    align-self: center;
}

.containeRequires .cardFlip .front .context .imgC {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.containeRequires .cardFlip .front .context img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

}

.containeRequires .cardFlip .front h2 {
    position: relative;
    z-index: 2;
    font-size: clamp(2.5rem, 3vw, 3.5rem);
    font-family: Summer;
    text-transform: uppercase;
    letter-spacing: 3px;
    font-weight: 700;
    color: #ffffff;
}

.containeRequires .cardFlip .back {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.342);
    backface-visibility: hidden;
    transform: rotateX(180deg);
    transition: 0.5s;
    border: 2px solid #FFF8E8;
}

.containeRequires .cardFlip:hover .back {
    transform: rotateX(0deg);
    display: flex;
    justify-content: center;
    align-items: center;
}

.containeRequires .cardFlip .listRequires {
    position: relative;
    z-index: 2;
}

.containeRequires .cardFlip .back .listRequires li i {
    color: orangered;
}

.containeRequires .cardFlip .back .listRequires li {
    margin: 0;
    padding: 0;
    list-style: none;
    color: white;
    font-size: clamp(1.1rem, 2vw, 1.4rem);
    font-family: cafe;
}

/* FOOTER */
.footerSection {
    position: relative;
    width: 100%;
    min-height: 100px;
    padding: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-image: linear-gradient(45deg, #222, #202020);
    z-index: 3;
}

.socialIconItem {
    list-style: none;
}

.socialIcon {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
    flex-wrap: wrap;

}

.socialIconLink {
    font-size: 1.5rem;
    color: #FF8235;
    margin: 0 20px;
    display: inline-block;
    transition: 0.5s;
}

.single-cta i {
    color: #FF8235;
    font-size: 30px;
    float: left;
    margin-top: 8px;
}

.cta-text {
    padding-left: 15px;
    display: inline-block;
}

.cta-text h4 {
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 2px;
    font-family: cafe;

}

.cta-text span {
    color: #757575;
    font-size: 15px;
    font-family: cafe;
    text-align: center;
}

.footerBot {
    position: relative;
    font-size: clamp(0.9rem, 1vw, 0.8rem);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    text-align: center;
    background-color: #FF8235;
    color: #202020;
    padding: 8px 0;
    z-index: 3;
}

.footerBot a {
    color: #f4511e;
    text-decoration: none;
    font-weight: 700;
    letter-spacing: 1px;
    font-family: cafe;
}

.footerBot a:hover {
    color: #ffffff;
}

/* TESTIMONIALS */
.fondoReviews {
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0c0d13;
}

.testimonials-section {
    width: 100%;
    padding: 0px 8%;
}

.testimonials-section .section-header {
    max-width: 700px;
    text-align: center;
    margin: 30px auto 40px;
}

.section-header h2 {
    margin-top: 7.5rem;
    position: relative;
    font-size: 2.3rem;
    color: #FF8235;
    font-family: cabin;
}

.testimonials-container {
    position: relative;
}

.testimonials-container .testimonial-card {
    padding: 20px;
}

.testimonial-card .test-card-body {
    background-color: #171922;
    box-shadow: 2px 2px 20px rgb(0, 0, 0);
    padding: 20px;
    min-height: 236px;
}

.test-card-body .quote {
    display: flex;
    align-items: center;
}

.test-card-body .quote i {
    font-size: 45px;
    color: #dadada;
    margin-right: 20px;
}

.test-card-body .quote h2 {
    color: #dadada;
}

.test-card-body p {
    margin: 10px 0px 15px;
    font-size: 14px;
    line-height: 1.5;
    color: #767a86;
}

.testimonial-card .profile {
    display: flex;
    align-items: center;
    margin-top: 25px;

}

.profile .profile-image {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 15px;
}

.profile .profile-image img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
}

.profile .rofile-desc {
    display: flex;
    flex-direction: column;

}

.profile .profile-desc span {
    font-size: 24px;
    font-weight: bold;
    color: #f0bf6a;
}

.divOpinion {
    margin: 2rem;
}

.divOpinion .btnOpinion {
    background: linear-gradient(105deg, #B3FFAB, #12FFF7);
    border-radius: 50px;
    padding: 10px 20px;
    font-weight: bold;
}

.divOpinion .btnOpinion a {
    color: #000000 !important;
    text-decoration: none;
}

.divOpinion .opinion {
    font-family: cafe;
    color: #30E8BF;
    letter-spacing: 2px;

}

/* Ratings */

.rating-outer {
    display: inline-block;
    margin-top: 15px;
    position: relative;
    font-family: FontAwesome;
    color: gray;
    font-size: clamp(0.8rem, 1.3vw, 1.3rem);
    cursor: pointer;
}

.rating-outer::before {
    content: '\f006 \f006 \f006 \f006 \f006';
}

.rating-inner {
    position: absolute;
    top: 0;
    left: 0;
    white-space: nowrap;
    overflow: hidden;
    width: 0;
}

.rating-inner::before {
    content: '\f005 \f005 \f005 \f005 \f005';
    font-size: clamp(0.8rem, 1.3vw, 1.3rem);
    color: #f0bf6a;
    cursor: pointer;
}

/* REVIEWS */
.fondoReview {
    background-image: url("./images/fondos/fondoServices.jpg");
    min-height: 100vh;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: bottom;
}

.reviews {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.reviews .headerReview {
    letter-spacing: 1px;
    margin-top: 7rem;
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.reviews .headerReview h2 {
    font-size: 2.2rem;
    font-weight: 500;
    background-color: #202020;
    color: white;
    padding: 10px 20px;
    text-transform: uppercase;
    font-family: Cabin;
}

.reviews .headerReview span {
    font-size: 1.3rem;
    color: #ffffff;
    margin-bottom: 10px;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-family: cafe;
}

.reviews .reviewsBoxContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 4rem;
}

.reviews .reviewsBoxContainer .reviewBox {
    width: 45%;
    min-height: 200px;
    box-shadow: 2px 2px 30px rgba(0, 0, 0, 0.849);
    background-color: #fff8e8;
    padding: 20px;
    margin: 15px;
    cursor: pointer;
}

.reviews .reviewsBoxContainer .reviewBox .client-comment p {
    font-size: 0.9rem;
    color: #4b4b4b;
}
.GoogleReviews
{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.GoogleReviews p
{
    font-family: Cafe;
    color: white;
}
.GoogleReviews .ContainerGoogle {
    border-radius: 40px !important;
    overflow: hidden;
    margin-top: 5px;
    border: solid 1px #FF8235;
    margin-top: 0px;
}

.GoogleReviews .ContainerGoogle button,
.GoogleReviews .ContainerGoogle div {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    background-color: transparent !important;
    color: white !important;
}

.ContainerGoogle:hover {
    background: #FF8235;
}
.boxTop {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.boxTop .profile {
    display: flex;
    align-items: center;
}

.boxTop .profile .nameUser {
    display: flex;
    flex-direction: column;
}

.boxTop .profile .nameUser strong {
    color: #3d3d3d;
    font-size: clamp(1rem, 1vw, 1.1rem);
    letter-spacing: 0.5px;

}

.boxTop .profile .nameUser span {
    color: #979797;
    font-size: clamp(0.8rem, 0.8vw, 0.8rem);
    letter-spacing: 0.5px;

}

.boxTop .profile .profile-img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 5px;
}

.boxTop .profile .profile-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

/* MODAL */
.divComentar {
    position: relative;
    width: 100%;
    margin-top: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
}

.divComentar button {
    border: none;
    background-color: #FF8235;
    font-family: cafe;
    text-transform: uppercase;
    font-weight: bolder;
    color: white;
    letter-spacing: 2px;
}

.divComentar button:hover {
    background-color: #30E8BF;
}

.divComentar a .btn {
    font-family: cafe;
    font-weight: 900;
    letter-spacing: 1px;
}

.divComentar a .btn:hover {
    background-color: #30E8BF;
    border: 1px solid #30E8BF;
    outline: none;
    background-color: #222;
    color: #999;
}

.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-height: 100vh;
    width: 100%;
    z-index: 999999 !important;
    background-color: rgba(0, 0, 0, .75);
    display: none;
    justify-content: center;
    align-items: center;
}

.modal.is-open {
    display: flex;
}

.modal-container {
    position: relative;
    width: 400px;
    background-color: #111;
    padding: 20px 30px;
    border: 1px solid #444;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.modal-close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 2rem;
    height: 2rem;
    border: 1px solid #FF8235;
    background-color: #222;
    color: #FF8235;
    font-weight: bold;
    border-radius: 5px;
    z-index: 9999;
}

.orange {
    color: #FF8235;
}

.yellow {
    color: #fdcc0d;
}

.stars {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 0;
    z-index: 9999;
    margin-top: 1rem;
}

.star {
    display: inline;
    list-style: none;
    font-size: 2rem;
    padding-left: 0.9rem;
    z-index: 9999;
}

.star:first-child {
    padding-left: 0;
}

.textarea {
    height: 100px;
    width: 100%;
    overflow: hidden;
}

.modal-body h4 {
    font-family: cabinest;
    letter-spacing: 2px;
    color: orange;
    text-align: center;
}

.textarea textarea {
    height: 100%;
    width: 100%;
    outline: none;
    border: 1px solid #333;
    background-color: #222;
    padding: 10px;
    color: #eee;
    resize: none;
}

.modal-body button {
    height: 100%;
    width: 100%;
    border: 1px solid #444;
    outline: none;
    background-color: #222;
    color: #999;
    font-size: 1.1rem;
    font-weight: 500;
    text-transform: uppercase;
}

.modal-body button:hover {
    border: 1px solid #FF8235;
    color: #FF8235 !important;
}

/* contactanos */
.contact {
    background-color: #00000079 !important;
    width: 100%;
    backdrop-filter: blur(.1px);
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
}

.sectionContact {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: #30E8BF;
}

.sectionContact::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background: #FF8235;
}

.containerContact {
    position: relative;
    min-width: 67rem;
    min-height: 550px;
    display: flex;
    z-index: 1000;
}

.containerContact .contactInfo {
    position: absolute;
    top: 40px;
    width: 350px;
    height: calc(100% - 80px);
    background: #0c0d13;
    z-index: 1;
    padding: 40px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0 20px 25px rgba(0, 0, 0, 0.15);
}

.containerContact .contactInfo h2 {
    color: #FF8235;
    font-size: 24px;
    font-weight: 500;
    font-family: summer;
    text-align: center;
}

.containerContact .contactInfo ul.info {
    position: relative;
    margin: 40px 0;
}

.containerContact .contactInfo ul.info li {
    position: relative;
    list-style: none;
    display: flex;
    margin: 20px 0;
    cursor: pointer;
    align-items: flex-start;
}

.containerContact .contactInfo ul.info li span:nth-child(1) {
    width: 30px;
    min-width: 30px;
    font-size: 30px;
    color: #fff8e8;
}

.containerContact .contactInfo ul.info li span:nth-child(2) {
    color: #fff8e8;
    margin-left: 15px;
    margin-top: 8px;
    font-family: cafe;
    font-weight: 300;
}

.containerContact .contactInfo ul.sci {
    position: relative;
    display: flex;
}

.containerContact .contactInfo ul.sci li {
    list-style: none;
    margin-right: 2rem;
}

.containerContact .contactInfo ul.sci li a {
    text-decoration: none;
    color: #FF8235;
    font-size: clamp(1.4rem, 1.7vw, 1.8rem);
}

.containerContact .contactForm {
    position: absolute;
    padding: 70px 50px;
    padding-left: 250px;
    margin-left: 150px;
    width: calc(100% - 150px);
    height: 100%;
    background: #FFF8E8;
    box-shadow: 0 50px 50px rgba(0, 0, 0, 0.25);
}

.containerContact .contactForm h2 {
    color: #0f3959;
    font-family: cafe;
    font-size: 24px;
    font-weight: bolder;
}

.containerContact .contactForm .formBox {
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 30px;

}

.containerContact .contactForm .formBox .inputBox {
    position: relative;
    margin-bottom: 30px;
}

.containerContact .contactForm .formBox .inputBox.w50 {
    width: 47%;

}

.containerContact .contactForm .formBox .inputBox.w100 {
    width: 100%;

}

.containerContact .contactForm .formBox .inputBox input,
.containerContact .contactForm .formBox .inputBox textarea {
    width: 100%;
    resize: none;
    padding: 5px 0;
    font-size: 18px;
    font-weight: 300;
    color: #333;
    border: none;
    outline: none;
    border-bottom: 1px solid #777;
    background: #FFF8E8;
}

.containerContact .contactForm .formBox .inputBox textarea {
    height: 120px;
}

.containerContact .contactForm .formBox .inputBox span {
    position: absolute;
    left: 0;
    padding: 5px 0;
    pointer-events: none;
    font-size: 18px;
    font-weight: 300;
    transition: 0.3s;
    font-family: cafe;
}

.containerContact .contactForm .formBox .inputBox input:focus~span,
.containerContact .contactForm .formBox .inputBox input:valid~span,
.containerContact .contactForm .formBox .inputBox textarea:focus~span,
.containerContact .contactForm .formBox .inputBox textarea:valid~span {
    transform: translateY(-20px);
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 1px;
    color: #095a55;
    font-weight: 500;
}

.containerContact .contactForm .formBox .inputBox input[type="submit"] {
    position: relative;
    cursor: pointer;
    background: orange;
    color: #fff;
    border: none;
    max-width: 150px;
    padding: 12px;
    font-family: cafe;
    font-weight: bolder;
}

.containerContact .contactForm .formBox .inputBox input[type="submit"]:hover {
    background-color: orangered;

}

.contact {

    background-color: #00000079 !important;
    width: 100%;
    min-height: 100vh;
    border: none;
}

/* LOGIN */
.containerLogin {
    background-color: #000c09;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}

.containerLogin .login {
    width: 50rem;
    height: 100%;
    overflow: hidden;
    border-radius: 5px;
    background-color: #222;
    display: flex;
    box-shadow: 15px 5px 30px rgba(0, 0, 0, .3);
    margin: 8rem 0px 4rem 0px;
}

.containerLogin .login .content {
    width: 100%;
    height: 31rem;
    flex-basis: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.containerLogin .login .content img {
    width: 100%;
    height: 100% !important;
    object-fit: cover;
    object-position: center;
}

.containerLogin .login .loginform {
    flex-basis: 50%;
    background-color: #111;
    padding: 20px;
}

.containerLogin .login .loginform h2 {
    font-family: cabin;
    text-transform: uppercase;
    color: #FF8235;
    font-weight: 600;
    letter-spacing: 2px;
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 2rem;
    font-size: 2em;
}

.containerLogin .login .loginform .inputBox {
    position: relative;
    margin: 1.5rem 0;
    border-bottom: 2px solid #FF8235;
}

.containerLogin .login .loginform .inputBox label {
    position: absolute;
    top: 50%;
    left: 5px;
    color: white;
    font-size: 1rem;
    pointer-events: none;
    transition: all 0.5s ease-in-out;
    font-family: cafe;
}

.containerLogin .login .loginform .inputBox input:focus~label,
.containerLogin .login .loginform .inputBox input:valid~label {
    transform: translateY(-20%);
    top: 1px;
}

.containerLogin .login .loginform .inputBox input {
    width: 100%;
    background: transparent;
    outline: none;
    font-size: 1rem;
    padding: 0 35px 0 5px;
    border: none;
    color: #fff8e8;
    font-family: cafe;
    margin-top: 1.5rem;
}

.containerLogin .login .loginform .inputBox i {
    position: absolute;
    right: 8px;
    color: #FF8235;
    font-size: 1.3rem;
    top: 16px;
    cursor: pointer;
}

.forget {
    margin: 35px 0;
    font-size: 0.85rem;
    color: #fff;
    display: flex;
    justify-content: space-between;
}

.forget label {
    display: flex;
    align-items: center;
}

.forget a {
    color: #4b4b4b !important;
}

.forget a:hover {
    text-decoration: underline;
}

.containerLogin .login .loginform button {
    width: 100%;
    height: 2.5rem;
    border-radius: 40px;
    border: solid 1px #FF8235;
    color: white;
    font-family: cafe;
    letter-spacing: 1px;
    outline: none;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 600;
    transition: all 0.4s ease;
}

.containerLogin .login .loginform button:hover {
    background: #FF8235;
}

.ContainerGoogle {
    border-radius: 40px !important;
    overflow: hidden;
    margin-top: 10px;
    border: solid 1px #FF8235;
}

.ContainerGoogle button,
.ContainerGoogle div {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    background-color: #111 !important;
    color: white !important;
}

.ContainerGoogle:hover {
    background: #FF8235;
}

.register {
    font-size: 0.9rem;
    color: white;
    text-align: center;
    margin: 25px 0 10px;
}

.register p a {
    color: #FF8235;
    font-weight: 600;
}

.register p a:hover {
    text-decoration: underline;
}

/* REGISTRO */
.containerRegistro {
    background-color: #000c09;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}

.boxRegistro {

    position: relative;
    width: 43rem;
    min-height: 38rem;
    background: #1c1c1c;
    border-radius: 8px;
    overflow: hidden;
    margin-top: 10rem;
    margin-bottom: 4rem;
    margin-inline: 0.3rem;
}

.boxRegistro::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 43rem;
    height: 45rem;
    background: linear-gradient(0deg, transparent, #FF8235, #FF8235);
    transform-origin: bottom right;
    animation: animateRegistro 6s linear infinite;
}

.boxRegistro::after {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 43rem;
    height: 45rem;
    background: linear-gradient(0deg, transparent, #FF8235, #FF8235);
    transform-origin: bottom right;
    animation: animateRegistro 6s linear infinite;
    animation-delay: -3s;
}

.formRegistro {
    position: absolute;
    inset: 2px;
    border-radius: 8px;
    background-color: #111;
    z-index: 10;
    padding: 3rem 2.5rem;
    display: flex;
    flex-direction: column;
}

.formRegistro h2 {
    font-family: cabin;
    letter-spacing: 0.1rem;
    color: #FF8235;
    font-weight: 500;
    text-align: center;
}

.formRegistro .inputBox {
    position: relative;
    width: 100%;
    margin-top: 35px;
}

.formRegistro .inputBox input {
    position: relative;
    width: 100%;
    padding: 20px 10px 10px;
    background: transparent;
    border: none;
    outline: none;
    color: #fff8e8;
    font-size: 1em;
    letter-spacing: 0.05em;
    font-family: cafe;
    z-index: 10;
}

#eye-icon {
    position: absolute;
    right: 8px;
    color: #111;
    font-size: 1.3rem;
    top: 16px;
    cursor: pointer;
    z-index: 9999;
}

#SeccionSelect {
    margin-top: -2rem;
}

.formRegistro .inputBox select {
    position: relative;
    width: 100%;
    background: transparent;
    border: solid 1px #FF8235;
    outline: none;
    color: #fff8e8;
    font-size: 1em;
    letter-spacing: 0.05em;
    font-family: cafe;
    z-index: 10;
    margin-top: 3rem;
    padding: 5px 10px;
}

.formRegistro .inputBox select option {
    background: #111;
}

.formRegistro .inputBox span {
    position: absolute;
    left: 0;
    padding: 20px 10px 10px;
    font-size: 1em;
    color: #8f8f8f;
    pointer-events: none;
    letter-spacing: 0.05em;
    transition: 0.5s;
}

.formRegistro .inputBox input:focus~span,
.formRegistro .inputBox input:valid~span {
    color: #FF8235;
    transform: translateY(-34px);
    font-size: 0.75em;
}

.formRegistro .inputBox select:focus~span,
.formRegistro .inputBox select:valid~span {
    color: #FF8235;
    transform: translateY(-1px);
}

.formRegistro .inputBox .raya {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background-color: #FF8235;
    border-radius: 4px;
    transition: 0.5s;
    pointer-events: none;

}

.formRegistro .inputBox input:focus~i,
.formRegistro .inputBox input:valid~i {
    height: 44px;
}

.formRegistro .inputBox .imagenRegistro {
    display: flex;
    margin-top: 1rem;
}

.formRegistro .inputBox .imagenRegistro #archivo {
    margin-top: 0.9rem;
}

.containerButtonsRegister {
    display: flex;
    flex-direction: row;
    gap: 2rem;
}

.formRegistro input[type="submit"] {
    border: none;
    outline: none;
    background-color: #FF8235;
    padding: 11px 25px;
    width: 12rem;
    border-radius: 4px;
    font-weight: 600;
    font-family: cafe;
    margin-top: 2.5rem;
    letter-spacing: 2px;
}

.formRegistro input[type="submit"]:hover {

    background-color: #30E8BF;

}

.ContainerGoogleRegistro {
    border-radius: 4px !important;
    overflow: hidden;
    margin-top: 10px;
    border: solid 1px #FF8235;
    width: 12rem;
    margin-top: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;

}

.ContainerGoogleRegistro button,
.ContainerGoogleRegistro div {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    background-color: #111 !important;
    color: white !important;
}

.ContainerGoogleRegistro:hover {
    background: #FF8235;
}

/* Avatar */
.avatar {
    display: inline-block;
    margin-bottom: 0;
    height: 4rem;
    width: 4rem;
    border-radius: 50%;
}

.avatar img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.avatar-nav {
    margin-right: 0.6rem;
    height: 2.2rem;
    width: 2.2rem;
}

@keyframes animateRegistro {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* Forgot Password */
.containerForgot {
    min-height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #111;
    padding-bottom: 2rem;
    padding-top: 8rem;
}

.square {
    width: 540px;
    height: 500px;
    padding: 50px 100px;
    background: #222;
    display: flex;
    border-radius: 5px 100px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.square .forgotPass {
    width: 440px;
    height: 430px;
    padding: 30px 30px 30px 30px;
    border-radius: 40px;
    background: #222;
    box-shadow: 13px 13px 20px #181818,
        -13px -13px 20px #2b2b2b;
}

.square .forgotPass .logo {
    background: url("./images/logoRnav.png");
    width: 100px;
    height: 100px;
    margin: auto;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.square .forgotPass .title {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.square .forgotPass .title span {
    color: #FF8235;
    font-family: cabinest;
    font-size: 1.7rem;
    margin-block: 1rem;
    letter-spacing: 2px;
    text-align: center;
}

.square .forgotPass .title p {
    color: #fff8e8;
    font-family: cafe;
    text-align: center;
}

.square .forgotPass .field {
    width: 100%;

}

.square .forgotPass .field input {
    width: 100%;
    border: none;
    outline: none;
    background: none;
    font-size: 18px;
    color: #FFF8E8;
    padding: 15px 10px 15px 30px;
    border-radius: 25px;
    margin-bottom: 25px;
    box-shadow: inset 8px 8px 8px #181818,
        inset -8px -8px 8px #2b2b2b;
}

.btnForgot {
    outline: none;
    border: none;
    cursor: pointer;
    width: 100%;
    height: 60px;
    border-radius: 30px;
    font-size: 20px;
    font-weight: 700;
    font-family: cafe;
    color: #FF8235;
    text-align: center;
    background-color: #FFF8E8;
}

/* Reset pass */
.squareReset {
    width: 540px;
    height: 600px;
    padding: 50px 100px;
    background: #222;
    display: flex;
    margin: 0 auto;
    border-radius: 5px 100px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.squareReset .resetPass {
    width: 440px;
    height: 500px;
    padding: 30px 30px 30px 30px;
    border-radius: 40px;
    background: #222;
    box-shadow: 13px 13px 20px #181818,
        -13px -13px 20px #2b2b2b;
}

.squareReset .resetPass .logo {
    background: url("./images/logoRnav.png");
    width: 100px;
    height: 100px;
    margin: auto;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.squareReset .resetPass .title {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.squareReset .resetPass .title span {
    color: #FF8235;
    font-family: cabinest;
    font-size: 1.7rem;
    margin-block: 1rem;
    letter-spacing: 2px;
}

.squareReset .resetPass .title p {
    color: #fff8e8;
    font-family: cafe;
    text-align: center;
}

.squareReset .resetPass .field {
    width: 100%;
}

.squareReset .resetPass .field input {
    width: 100%;
    border: none;
    outline: none;
    background: none;
    font-size: 18px;
    color: #FFF8E8;
    padding: 15px 10px 15px 30px;
}

.squareReset .resetPass .field .pass {
    position: relative;
    margin-bottom: 25px;
    border-radius: 25px;
    box-shadow: inset 8px 8px 8px #181818,
        inset -8px -8px 8px #2b2b2b;
}

.squareReset .resetPass .field .pass #eyes {
    position: absolute;
    color: #ff8b2c;
    right: 15px;
    font-size: 1.3rem;
    top: 16px;
    cursor: pointer;
    z-index: 9999;
}

/* PROFILE */
.containerProfile {
    position: relative;
    width: 100%;
    min-height: 100vh;
    background: linear-gradient(205deg, #A1FFCE, #ffd194);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.titleContainer {
    position: relative;
    z-index: 1;
}

#contactTitulo {
    font-family: cabin;
    color: #FF8235;
    letter-spacing: 2px;
    margin: 7.5rem 4rem -6rem;
    text-align: center;
}

.boxProfile {
    margin: 7rem 2rem 5rem;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
}

.containerSettings {
    background-color: #fff8e8;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem 0 3rem;
    border-radius: 1rem;
    width: 24rem;
    margin-right: 2rem;
}

.containerSettings .username {
    margin-block: 1rem;
}

.containerSettings .username h5 {
    text-align: center;
    font-family: cafe;
    font-weight: 700;
    color: #FF8235;
    letter-spacing: 1px;
}

.containerSettings .listSettings {
    width: 100%;
    text-align: center;
}

.containerSettings .listSettings .listGroup {
    width: 100%;
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.containerSettings .listSettings .listGroup .groupItem {
    width: 100%;
    height: 3rem;
    padding: 0.8rem 0;
    font-weight: 500;
}

.containerSettings .listSettings .listGroup .groupItem:hover {
    background-color: #ff823586;
}

.containerSettings .listSettings .listGroup .groupItem a {
    color: #000;
    font-family: cafe;
    text-decoration: none;
}

.containerSettings .listSettings .listGroup .groupItem.active {
    background-color: #FF8235;
    font-weight: 800;
}

.boxColorProfileCard {
    background: transparent;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
    width: 15rem;
    height: 15rem;
}

.boxColorProfileCard::before {
    content: '';
    position: absolute;
    inset: -30px 0px;
    background: linear-gradient(315deg, #30E8BF, #FF8235);
    transition: 0.5s;
    animation: animateProfile 4s linear infinite;
}

.boxColorProfileCard::after {
    position: absolute;
    content: '';
    inset: 10px;
    background-color: transparent;
    border-radius: 50%;
    z-index: 1;
}

.contentProfile {
    position: absolute;
    inset: 8px;
    z-index: 100;
    border-radius: 50%;
    overflow: hidden;
}

.contentProfile img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.containerTab {
    width: 60rem;
    height: 100%;
}

/* update password*/
.containerUpdatePassword {
    width: 100%;
    height: 100%;
    border-radius: 15px;
    background: #fff8e875;
    padding: 1rem 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.updatePassword {
    width: 100%;
    min-height: 100%;
    background-color: #ecf0f3;
    border: 1px solid #FF8235;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
    padding: 2rem;
}

.updatePassword h2 {
    text-align: center;
    font-family: cabin;
    font-size: 1.3rem;
    letter-spacing: 1px;
    font-weight: 600;
    text-transform: uppercase;
    background: none;
    color: #FF8235;
    margin: 0 0 2rem;
}

.updatePassword .boxUpdatePassword {
    width: 80%;
    height: 90%;
    background-color: #ecf0f3;
    padding: 1rem 2rem 2rem 2rem;
    border-radius: 40px;
    box-shadow: 13px 13px 20px #cbced1,
        -13px -13px 20px #ffffff;
}

.logo {
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo #avatarPass {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.boxUpdatePassword .username {
    text-align: center;
    font-size: 1rem;
    padding-top: 1rem;
    letter-spacing: 3px;
    font-family: cafe;
    font-weight: 600;
    text-transform: uppercase;
}

.fields {
    width: 100%;
    padding: 20px 5px 5px 5px;
}

.boxUpdatePassword .fields .pass input {
    width: 100%;
    border: none;
    outline: none;
    background: none;
    font-size: 1rem;
    color: #000000;
    padding: 4px 20px 4px 20px;
}

.boxUpdatePassword .fields .pass {
    position: relative;
    border-radius: 25px;
    box-shadow: inset 8px 8px 8px #cbced1,
        inset -8px -8px 8px #ffffff;
}

#lblUpdate {
    font-family: cafe;
    font-weight: bold;
}

.boxUpdatePassword .fields .pass #eyes {
    position: absolute;
    color: #ff8b2c;
    right: 15px;
    font-size: 1.3rem;
    cursor: pointer;
    z-index: 9999;
}

.btnUpdatePass {
    outline: none;
    border: none;
    cursor: pointer;
    width: 100%;
    height: 2.5rem;
    border-radius: 30px;
    font-size: 20px;
    font-weight: 700;
    font-family: cafe;
    color: #fff8e8;
    text-align: center;
    background-color: #FF8235;
    margin-top: 1rem;
}

/* UPDATE FOTO */
.updateFoto {
    width: 100%;
    height: 100%;
    padding: 1.5rem 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.updateFoto h2 {
    text-align: center;
    font-family: cabin;
    font-size: 1.5rem;
    letter-spacing: 1px;
    font-weight: 600;
    text-transform: uppercase;
    background: none;
    color: orangered;
    margin: 1rem 0 2rem;
}

.updateFoto .formFoto {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.boxColorUpdateProfile {
    position: relative;
    width: 16rem;
    height: 17rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.boxColorUpdateProfile::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(45deg, #30E8BF, #FF8235);
}

.boxColorUpdateProfile::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(45deg, #30E8BF, #FF8235);
    filter: blur(15px);

}

.containtUpdateProfile img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 3;
    scale: 0.9;
}

.containtUpdateProfile b {
    position: absolute;
    inset: 6px;
    background: rgba(255, 255, 255, 0.705);
    z-index: 2;
}

.divUpdateFoto {
    margin-top: 2rem;
}

/* UPDATE PROFILE*/

.updateProfile {
    width: 100%;
    min-height: 100%;
    background-color: #ecf0f3;
    border: 1px solid #30E8BF;
    border-radius: 15px;
    overflow: hidden;
    padding: 2rem;
}

.updateProfile .info {
    margin-bottom: 25px;
}

.updateProfile .info h4,
.updateProfile .updateData h4 {
    margin-bottom: 15px;
    padding-bottom: 5px;
    border-bottom: 1px solid #e0e0e0;
    color: orangered;
    text-transform: uppercase;
    letter-spacing: 4px;
    font-family: cafe;
    font-size: 1rem;
    font-weight: bold;
}

.updateProfile .info .infoData {
    display: flex;
    justify-content: space-between;
}

.updateProfile .info .infoData .data {
    width: 45%;
}

.updateProfile .info .infoData .data h5 {
    color: #FF8235;
    font-family: cafe;
    letter-spacing: 2px;
    font-size: .8rem;
    font-weight: 800;
    text-transform: uppercase;
}

.updateProfile .info .infoData .data p {
    font-family: cafe;
    letter-spacing: 1px;
    font-size: .9rem;
    font-weight: 800;
}

.updateProfile .updateData .formData {
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: .5rem;
}

.updateProfile .updateData .fields.w50 {
    width: 47%;

}

.updateProfile .updateData .fields .pass input,
.updateProfile .updateData .fields .pass select {
    width: 100%;
    border: none;
    outline: none;
    background: none;
    font-size: 1rem;
    color: #000000;
    padding: 4px 20px 4px 20px;
}

.updateProfile .updateData .fields .pass {
    position: relative;
    border-radius: 25px;
    box-shadow: inset 8px 8px 8px #cbced1,
        inset -8px -8px 8px #ffffff;
}

/* SIDEBAR */
.sidebar {
    position: fixed;
    width: 80px;
    height: 100vh;
    background-color: #111;
    transition: 0.5s;
    overflow: hidden;
    z-index: 2;
}

.sidebar.expanded {
    width: 300px;
}

.sidebar ul {
    position: relative;
    height: 100vh;
    margin-top: 13rem;
    padding-left: 10px;
}

.sidebar ul li {
    position: relative;
    list-style: none;
}

.sidebar ul li.active {
    background: #333;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
}

.sidebar ul li.logoR {
    margin-top: 50px;

}

.sidebar ul li.logoR a #icon {

    padding-left: 5px;
}

.sidebar ul li.logoR a #text {
    font-size: 1.5em;
    font-weight: 500;
    letter-spacing: 3px;
    font-family: cabinest;
    color: orangered;
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 15px;
}

.sidebar ul li a {
    position: relative;
    display: flex;
    white-space: nowrap;
    text-decoration: none;
}

.sidebar ul li a .icon {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 60px;
    padding-left: 10px;
    height: 70px;
    font-size: 1.5rem;
    color: #fff8e8;
    transition: 0.5s;
    z-index: 9999;
}

.sidebar ul li a .text {
    position: relative;
    height: 70px;
    display: flex;
    align-items: center;
    font-size: 1em;
    color: #fff8e8;
    padding-left: 15px;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    transition: 0.5s;
}

.sidebar ul li.active a {
    position: relative;
}

.sidebar ul li.active a .icon {
    color: #111;
    position: relative;
}

.sidebar ul li.active a .text {
    color: var(--bg);
}

.sidebar ul li:hover a .icon,
.sidebar ul li:hover a .text {
    color: var(--bg);
}

.sidebar ul li.active a .icon::before {
    content: '';
    position: absolute;
    inset: 5px;
    width: 60px;
    background: var(--bg);
    z-index: -1;
    border-radius: 50%;
    transition: 0.5s;
}

.sidebar ul li:hover.active a .icon::before {
    background-color: #000000;
}

.menuToggle {
    position: fixed;
    top: 8.5rem;
    left: 22px;
    width: 40px;
    height: 40px;
    background-color: #FF8235;
    z-index: 9999;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
}

.menuToggle .toggle-btn {
    color: #fff8e8;
    font-size: 2rem;
    cursor: pointer;
}

/* DASHBOARD */
#panelControl {
    color: orangered;
    font-family: cabin;
    margin-top: 4rem;
    margin-bottom: 3rem;
}

.containerDashboard {
    width: 100%;
    min-height: 100vh;
    background: linear-gradient(50deg, #CC95C0, #DBD4B4, #7AA1D2);
}

.flexContainer {
    width: 70%;
    height: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.flexContainer .flexBox {
    width: 250px;
    height: 250px;
    background: #fff8e8;
    text-align: center;
    border-radius: 20px;
    margin: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.flexContainer .flexBox .content {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.flexContainer .flexBox .content .title {
    position: relative;
    top: 20px;
    font-family: cabinest;
    font-size: clamp(1.5rem, 1.6vh, 1.8rem);
    letter-spacing: 2px;
}

.flexContainer .flexBox .content .cant {
    position: relative;
    margin-top: 2rem;
    width: 100%;
    height: 100%;
    background-color: #ffbc57;
    display: flex;
    justify-content: center;
    align-items: center;
}

.flexContainer .flexBox .content .cant p {
    text-align: center;
    font-size: 2rem;
    font-family: cafe;
    color: #111;
    padding-top: .6rem;
    font-weight: 600;
}

.flexContainer .flexBox a span {
    color: #FF8235;
    padding: 1rem 0.4rem 1rem;
    font-family: cafe;
    letter-spacing: 2px;
}

/* DASHBOARD SERVICELIST*/
#serviceList {
    color: orangered;
    text-align: center;
    font-family: cabin;
    text-transform: uppercase;
    margin-top: 7.5rem;
}

.containerServiceList {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    padding: 0 1rem;
}

.containerServiceList .boxServiceList {
    width: 80%;
    height: 70%;
    overflow-y: scroll;
    margin-bottom: 2rem;
}

.containerServiceList .boxServiceList th {
    color: #FF8235;
    font-family: cafe;
    text-transform: uppercase;
    letter-spacing: 3px;
}

.containerServiceList .boxServiceList td {
    color: #000;
    padding: 1rem;
    font-family: cafe;
    align-items: center;
}

.containerServiceList .boxServiceList thead {
    background-color: #111;
    border: solid 2px #FF8235;
}

.containerServiceList .boxServiceList tr:hover td {
    background-color: #ff8235b2;
    color: rgb(255, 255, 255);

}

.containerServiceList .boxServiceList td:nth-child(1) {
    width: 15%;
    background-color: #FF8235;
    color: rgb(255, 255, 255);
}

.containerServiceList .boxServiceList td:nth-child(2) {
    width: 63%;
}

.containerServiceList #userList td:nth-child(2) {
    width: 20%;
}

.containerServiceList .boxServiceList td:nth-child(3) {
    width: 5%;
}

.containerServiceList .boxServiceList td a:nth-child(1) {
    background-color: #F2994A;
    padding: 0 5px;
    margin-right: 0.3rem;
}

.containerServiceList .boxServiceList td a:nth-child(2) {
    background-color: #30E8BF;
    padding: 0 5px;
    margin-right: 0.3rem;
}

.containerServiceList .boxServiceList td button {
    background-color: #EF3B36;
    padding: 0 5px;
    margin-right: 0.1rem;
}

/* container new service */
.containerNewService {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100vh;
    overflow-y: scroll;
}

.boxNewService {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 90%;
    height: 100%;
    margin-top: 8rem;
}

.boxNewService .inputBox {
    width: 20rem;
}

.boxNewService .inputBox label {
    font-family: cafe;
    font-weight: 600;
    color: #111;
}

.boxNewService .inputBox input {
    width: 100%;
}

#btnAgregar {
    border: none;
    background-color: #FF8235;
    font-family: cafe;
    text-transform: uppercase;
    font-weight: bolder;
    color: white;
    letter-spacing: 2px;
    font-size: clamp(0.8rem, 1vh, 1rem);
    width: 48%;
    margin-right: .5rem;
    border-radius: 5px;
}

#btnUpdate {
    border: none;
    background-color: #30E8BF;
    font-family: cafe;
    text-transform: uppercase;
    font-weight: bolder;
    color: #111;
    letter-spacing: 2px;
    font-size: clamp(0.8rem, 1vh, 1rem);
    width: 48%;
}

#btnAgregar:hover {
    background-color: #30E8BF;
}

#newService {
    color: orangered;
    text-align: center;
    font-family: cabin;
    text-transform: uppercase;
    font-weight: 600;
}

/* update service*/
.containerUpdateServices {
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.containerUpdateServices .boxUpdateService {
    width: 35%;
    height: 100%;
    padding-inline: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}


.containerUpdateServices .boxUpdateService .inputBox {
    margin-bottom: 1.5rem;
}

.containerUpdateServices .boxUpdateService .inputBox label {
    font-family: cafe;
    letter-spacing: 1px;
    font-weight: 600;
}

.containerUpdateServices .boxListServices {
    width: 55%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.containerUpdateServices .boxListServices .boxServiceList {
    width: 95%;
    overflow-y: scroll;
}

.containerUpdateServices .boxListServices .boxServiceList th {
    color: #FF8235;
    font-family: cafe;
    text-transform: uppercase;
    letter-spacing: 3px;
}

.containerUpdateServices .boxListServices .boxServiceList td {
    color: #000;
    padding: 1rem;
    font-family: cafe;
    align-items: center;
}

.containerUpdateServices .boxListServices .boxServiceList thead {
    background-color: #111;
    border: solid 2px #FF8235;
}

.containerUpdateServices .boxListServices .boxServiceList tr:hover td {
    background-color: #ff8235b2;
    color: rgb(255, 255, 255);

}

.containerUpdateServices .boxListServices .boxServiceList td:nth-child(1) {
    width: 15%;
    background-color: #FF8235;
    color: rgb(255, 255, 255);
}

.containerUpdateServices .boxListServices .boxServiceList td:nth-child(2) {
    width: 60%;
}

.containerUpdateServices .boxListServices .boxServiceList td:nth-child(3) {
    width: 3%;
}

.containerUpdateServices .boxListServices .boxServiceList td a:nth-child(1) {
    background-color: #F2994A;
    padding: 0 5px;
    margin-right: 0.3rem;
}

.containerUpdateServices .boxListServices .boxServiceList td button {
    background-color: #EF3B36;
    padding: 0 5px;
    margin-right: 0.1rem;
}

/* UPDATE USER */
.contenedorCentralizado {
    display: flex;
    width: 100%;
    min-height: 100vh;
    justify-content: center;
    align-items: center;
    padding-top: 4rem;
    flex-direction: column;
}

.containerUpdateUser {
    width: 100%;
    min-height: 100vh;
    background: linear-gradient(205deg, #A1FFCE, #ffd194);
}

.containerUpdateUser .boxPerfil {
    margin-top: 8rem;
    margin-bottom: 3rem;
    position: relative;
    width: 340px;
    height: 650px;
    background-color: #fff8e8;
    border-radius: 20px;
}

.containerUpdateUser .boxPerfil .containerFoto {
    position: absolute;
    top: -70px;
    left: 50%;
    width: 150px;
    height: 150px;
    background: #fff8e8;
    transform: translateX(-50%);
    border-radius: 20px;
    box-shadow: 0 15px 50px rgba(0, 0, 0, .35);
    overflow: hidden;
}

.containerUpdateUser .boxPerfil .containerFoto img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.containerUpdateUser .boxPerfil .containerUpdate {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.containerUpdateUser .boxPerfil .containerUpdate label {
    font-family: cafe;
    color: #000;
    font-weight: 600;
    letter-spacing: 1px;
}

#updateUser {
    font-family: cabin;
    color: orangered;
    letter-spacing: 2px;
    text-align: center;
    margin-top: 1.5rem;
}

/* LOADING PAGE */
.containerLoading {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #111;
}

.loader {
    display: block;
    position: relative;
    width: 300px;
    height: 300px;
    border-radius: 50%;
    border: 7px solid transparent;
    border-top-color: #30E8BF;
    animation: spin 2s linear infinite;
}

.loader::before,
.loader::after {
    content: '';
    position: absolute;
    border-radius: 50%;
    border: 7px solid transparent;
}

.loader::before {
    top: 10px;
    left: 10px;
    right: 10px;
    bottom: 10px;
    border-top-color: #FF8235;
    animation: spin 3s linear infinite;
}

.loader::after {
    top: 25px;
    left: 25px;
    right: 25px;
    bottom: 25px;
    border-top-color: #FFF8E8;
    animation: spin 1.5s linear infinite;
}



/* Preloader */
.preloader {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 999;
}

.spinner {
    display: block;
    position: relative;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    border: 7px solid transparent;
    border-top-color: #30E8BF;
    animation: spin 2s linear infinite;
}

.spinner::before,
.spinner::after {
    content: '';
    position: absolute;
    border-radius: 50%;
    border: 7px solid transparent;
}

.spinner::before {
    top: 9px;
    left: 9px;
    right: 9px;
    bottom: 9px;
    border-top-color: #FF8235;
    animation: spin 3s linear infinite;
}

.spinner::after {
    top: 18px;
    left: 18px;
    right: 18px;
    bottom: 18px;
    border-top-color: #FFF8E8;
    animation: spin 1.5s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* RESPONSIVE MEDIA */
@media only screen and (max-width: 1250px) {
    .containerUpdateServices {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

    }

    .containerUpdateServices .boxUpdateService {
        width: 100%;
        margin-top: 8rem;
    }

    .containerUpdateServices .boxListServices {
        margin-top: 4rem;
        margin-left: 2rem;
        margin-bottom: 2rem;
        width: 85%;

    }

}

@media only screen and (max-width: 1200px) {
    .containerContact {
        width: 90%;
        min-width: auto;
        margin: 20px;
        box-shadow: 0 20px 50px rgba(0, 0, 0, .2);
    }

    .containerContact .contactInfo {
        top: 0;
        height: auto;
        position: relative;
    }

    .containerContact .contactForm {
        position: relative;
        width: calc(100% -350px);
        padding-left: 0;
        margin-left: 0;
        padding: 2.5rem;
        height: 34.3rem;
        box-shadow: none;
    }
}

@media only screen and (max-width: 992px) {
    .sectionContact {
        background-color: #FF8235;
    }

    .sectionContact::before {
        display: none;
    }

    .containerContact {
        display: flex;
        flex-direction: column-reverse;
    }

    .containerContact .contactForm {
        width: 100%;
        height: auto;
    }

    .containerContact .contactInfo {
        width: 100%;
        height: auto;
        flex-direction: row;
    }

    .containerContact .contactInfo ul.sci {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .containerContact .contactForm .formBox .inputBox input[type="submit"] {
        background: #30E8BF;
        color: #000;
        font-weight: bolder;
        font-family: cafe;
    }

    .containerContact .contactForm .formBox .inputBox input[type="submit"]:hover {
        background-color: #3cffb4;

    }

    .boxProfile {
        display: flex;
        flex-direction: column
    }

    .containerSettings {
        width: 100%;
        height: auto;
        margin: 0;
    }

    .containerTab {
        width: 100%;
        height: auto;
    }
}

/* RESPONSIVE DESIGN*/
@media(max-width: 992px) {

    .navbar .nav-item,
    .navbar .btn-nav,
    .navbar .btn-navLoguin {
        display: none;
    }

    .dropdownbutton {
        display: block;
    }
}

@media only screen and (max-width: 992px) {
    .wrapperDiv {
        width: 100% !important;
        position: static;
    }

    .custom-btn {
        width: 100% !important;
        margin-bottom: .1rem;
    }

    .about .aboutDiv .aboutTitulo {
        margin-bottom: 0.1rem;
    }

    .fondoAux .aboutDiv .aboutTitulo {
        margin-top: 6rem;
    }

    .text-about {
        margin-top: 3rem !important;
    }

    .containeRequires {
        margin-top: 1rem !important;
    }

    .socialIconLink {
        margin: 0 10px !important;
    }

    .infoAbout {
        width: 100%;
        position: static;
        padding: 45px 25px;
        text-align: center;
    }


}


@media only screen and (max-width: 790px) {
    .reviews .reviewsBoxContainer .reviewBox {
        width: 100% !important;
    }

    .containerLogin .login {
        flex-direction: column;
        margin: 0 15px;
    }

    .containerLogin .login .content img {
        height: 20rem;
        object-fit: cover;
        object-position: bottom;
    }

    .containerLogin .login .loginform {
        padding-inline: 7rem;
    }
}

@media only screen and (max-width: 768px) {
    .boxRegistro {
        height: 62rem;
        margin-top: 8rem;
        margin-inline: 1.2rem;
        margin-bottom: 3rem;
    }

    .boxRegistro::before {
        height: 62rem;
    }

    .boxRegistro::after {
        height: 62rem;
    }

    .updateProfile .info .infoData {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .updateProfile .info .infoData .data {
        width: 100%;
    }

    .sidebar {
        display: none;
        /* Oculta la barra lateral por defecto */
    }

    .sidebar-mobile {
        display: block;
        /* Muestra la barra lateral solo en pantallas pequeñas cuando está activa */
    }

    .menuToggle {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .containerUpdateServices .boxListServices {
        margin-left: 0px;
        width: 95%;

    }

    .responsive-Navlogo {
        max-width: 115px;
        height: auto;
    }
}

@media only screen and (max-width: 600px) {
    .containerContact .contactForm {
        padding: 1.5rem;

    }

    .containerContact .contactInfo {
        padding: 1.5rem;
        flex-direction: column;
        align-items: flex-start;
    }

    .containerContact .contactInfo ul.sci {
        margin-top: 1rem;
    }

    .containerContact .contactForm .formBox .inputBox.w50 {
        width: 100%;
    }

    .containerLogin .login .loginform {
        padding-inline: 3rem;
    }

    .square {
        width: 400px;
        height: 100%;
    }

    .square .forgotPass {
        width: 340px;
        height: 100%;
    }

    .squareReset {
        width: 400px;
        height: 100%;
        margin-inline: 1rem;
    }

    .squareReset .resetPass {
        width: 340px;
        height: 100%;
    }

    .updateProfile .updateData .fields.w50 {
        width: 100%;

    }

    .updatePassword {
        padding: 2rem 0;
    }

    .updatePassword .boxUpdatePassword {
        width: 90%;
        background-color: #ecf0f3;
        padding: 1rem 0.5rem 1rem;
    }

    .containerServiceList .boxServiceList th {
        display: flex;
        flex-direction: column;
    }

    .containerServiceList .boxServiceList td {
        display: flex;
        flex-direction: column;
    }

    .containerServiceList .boxServiceList td:nth-child(1) {
        width: 100%;
    }

    .containerServiceList .boxServiceList td:nth-child(2) {
        width: 100%;
    }

    .containerServiceList .boxServiceList td:nth-child(3) {
        width: 100%;
    }

    .containerServiceList .boxServiceList td:nth-child(4) {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .containerServiceList #userList td:nth-child(2) {
        width: 100%;
    }

    .containerServiceList .boxServiceList td:nth-child(6) {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .containerUpdateServices .boxListServices .boxServiceList th {
        display: flex;
        flex-direction: column;
    }

    .containerUpdateServices .boxListServices .boxServiceList td {
        display: flex;
        flex-direction: column;
    }

    .containerUpdateServices .boxListServices .boxServiceList td:nth-child(1) {
        width: 100%;
    }

    .containerUpdateServices .boxListServices .boxServiceList td:nth-child(2) {
        width: 100%;
    }

    .containerUpdateServices .boxListServices .boxServiceList td:nth-child(3) {
        width: 100%;
    }

    .containerUpdateServices .boxListServices .boxServiceList td:nth-child(4) {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .containerUpdateServices .boxListServices .boxServiceList td:nth-child(5) {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
}

@media only screen and (max-width: 576px) {

    .dropdown_menu.show {
        padding: 20px;
    }

    .containerLogin .login {
        margin-top: 3rem;
    }

    .containerLogin .login .content img {
        height: 15rem;
        object-fit: cover;
        object-position: bottom;
    }

    .containerCard .boxCard {
        position: relative;
        width: 300px;
        height: 430px;
        margin: 20px 30px;
        transition: 0.5s;
    }

    .modal-container {
        width: 300px;
        height: auto;
    }

    .reviews .headerReview h2 {
        font-weight: bold;
    }

    .formRegistro input[type="submit"] {

        width: 10rem;
        border-radius: 45px;
    }

    .ContainerGoogleRegistro {
        border-radius: 50% !important;
        /* Hace el contenedor completamente redondo */
        width: 3rem;
        height: 3rem;
        overflow: hidden;
        /* Oculta cualquier contenido que se salga del círculo */
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #111 !important;
        /* Color de fondo negro */
    }

    .ContainerGoogleRegistro button div {
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        width: 100%;
        height: 100%;
        position: relative;
        margin-right: 0px !important;
    }

    .ContainerGoogleRegistro button span {
        display: none !important;
    }
    #SeccionSelect {
        margin-top: -1rem;
    }
}

@media only screen and (max-width: 400px) {

    .square {
        width: 350px;
        height: 100%;
        margin-inline: 1rem;
    }

    .square .forgotPass {
        width: 300px;
        height: 100%;
    }

    .squareReset {
        width: 350px;
        height: 100%;
        margin-inline: 1rem;
        margin-top: 2rem;
    }

    .squareReset .resetPass {
        width: 300px;
        height: 100%;
    }

    .boxNewService .inputBox {
        width: 15rem;
    }
}

/* FONTS */

@font-face {
    font-family: 'Cafe';
    src: url('./font/cafe.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cabin';
    src: url('./font/Cabinest.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cabinest';
    src: url('./font/cabinest.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Summer';
    src: url('./font/Summerbee.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}