
.scroll {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 400px;
}
.scrollService {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 350px;
}

.scroll::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  background-color: #53303071;
}

.scroll::-webkit-scrollbar-thumb {
  background-color: #7e0f0f9a;
}
.scrollService::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  background-color: #ffffff;
}

.scrollService::-webkit-scrollbar-thumb {
  background-color: #ff06069a;
}

